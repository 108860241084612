import { useEffect } from 'react';

import BetonlyFaviconIcon from '~assets/betonly-logo.webp';
import SemabetFaviconIcon from '~assets/semabet-logo.webp';

export const useGetFaviconUrl = () => {
  useEffect(() => {
    if (document && document.querySelector("link[rel='icon']")) {
      const element = document.querySelector("link[rel='icon']");

      if (element) {
        const favicon = getFaviconUrl();

        if (favicon) {
          element.setAttribute('href', favicon);
        }
      }
    }
  }, []);

  const getFaviconUrl = () => {
    const hostname = window.location.hostname;
    const semabetHosts = [
      'http://semabet.ug',
      'http://semabet.co.zm',
      'http://semabet.co.mz',
      'http://semabet.co.tz',
    ];

    if (semabetHosts.some((host) => hostname.includes(host))) {
      return SemabetFaviconIcon;
    }

    if (hostname.includes('betonly')) {
      return BetonlyFaviconIcon;
    }

    return null;
  };
};
